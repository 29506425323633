import React from 'react';
import { Link } from 'gatsby';
import { createUseStyles } from 'react-jss';

import breakpoints from '../services/breakpoints';

interface Props {
  children: React.ReactNode;
  thumb?: React.ReactNode;
}

const useStyles = createUseStyles({
  '@global': {
    body: {
      background: '#333',
      transition: 'background 1s',
      padding: 0,
      margin: 0,
    },
  },

  container: {
    paddingTop: 32,
    paddingBottom: 32,
    fontFamily: 'mohave',
    color: 'white',
    margin: 'auto',
    [breakpoints.m]: {
      display: 'flex',
      width: 740,
    },
    [breakpoints.l]: {
      display: 'flex',
      width: 1200,
    },
  },

  header: {
    flexGrow: 1,
    textAlign: 'center',
    paddingBottom: 32,

    [breakpoints.m]: {
      paddingRight: 16,
      textAlign: 'right',
      paddingBottom: 0,
      position: 'relative',

      '&::after': {
        content: ' ',
        display: 'block',
        width: 1,
        background: 'white',
        height: 250,
        position: 'absolute',
        top: 0,
        right: 4,
      },
    },
  },

  content: {
    margin: [8, 16, 0, 16],
    [breakpoints.m]: {
      minWidth: 524,
    },
    [breakpoints.l]: {
      minWidth: 888,
    },
  },

  thumbContainer: {
    width: 96,
    marginTop: 20,
    borderRadius: 16,
    overflow: 'hidden',
    display: 'none',
    [breakpoints.m]: {
      display: 'inline-block',
    },
  },

  title: {
    fontSize: '1.8rem',
    margin: [0, 0, 4, 0],
    fontWeight: 600,
    color: 'white',
    '& *': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },

  subtitle: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontWeight: 300,
    fontSize: '1rem',
    margin: 0,
  },
});

function Layout({ children, thumb = null }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <h1 className={classes.title}>
          <Link to="/">minipainting.eu</Link>
        </h1>
        <div className={classes.subtitle}>
          ©STuFF.
          {new Date().getFullYear()}
        </div>
        {thumb && <div className={classes.thumbContainer}>{thumb}</div>}
      </header>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default Layout;
